import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Link,
  TextField,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import cssIcon from "../images/icons/css_icon.png";
import expressIcon from "../images/icons/express_icon.png";
import htmlIcon from "../images/icons/html_icon.png";
import jqueryIcon from "../images/icons/jquery_icon.png";
import jsIcon from "../images/icons/js_icon.jpg";
import mongoDbIcon from "../images/icons/mongodb_icon.png";
import nodeIcon from "../images/icons/node_icon.png";
import reactIcon from "../images/icons/react_icon.png";
import reduxIcon from "../images/icons/redux_icon.png";
import pugIcon from "../images/icons/pug_icon.png";
import tsIcon from "../images/icons/typescript_icon.png";
import webpackIcon from "../images/icons/webpack_icon.svg";
import sassLessIcon from "../images/logos/sass-less.png";
import postmanIcon from "../images/icons/postman.png";

const projects = [
  {
    title: "MealBase",
    url: "https://mealbase.app",
    github: "https://github.com/TJBlackman/mealbase-lite",
    postman: "https://documenter.getpostman.com/view/1087455/TVzXBuX8",
    description:
      "MealBase is a web app that lets users organize their favorite recipes from all over the internet. Registered users can add recipes from any website, favorite recipes they like, create and organize their own cookbooks, and generate weekly recipe lists called mealplans. MealBase is an actively developed progressive web application with new features released frequently.",
    stack: [
      "HTML 5",
      "CSS 3",
      "Javascript",
      "Typescript",
      "React",
      "Webpack",
      "NodeJS",
      "Express",
      "MongoDB",
      "Postman",
    ],
    features: [
      "Add Recipes with One Click",
      "Custom Cookbooks",
      "Weekly Mealplans",
    ],
  },
  {
    title: "MultiSlider",
    url: "https://multislider.trevorblackman.io",
    github: "https://github.com/TJBlackman/Multislider",
    description:
      "Multislider is a jQuery powered slideshow that specializes in showing more than one slide at a time. There's no need to find messy CSS and JS work arounds for other single-slide solutions. Multislider allows the developer to focus fully on each individual slide as it's own component, and then displays as many slides as you decide in a manner that is fluid, consistent, and dymanic.",
    stack: ["HTML 5", "CSS 3", "Javascript", "jQuery"],
    features: [
      "Hundreds of downloads",
      "Fully responsive",
      "Flexable layout options",
      "Customizable options",
    ],
  },
  {
    title: "React & Redux iTunes",
    github: "https://github.com/TJBlackman/React-Redux-iTunes",
    url: "https://react-redux-itunes.netlify.com",
    stack: ["HTML 5", "CSS 3", "Javascript", "React", "Redux"],
    description:
      "A small demo project I built while learning about Redux and state managment in general. It interacts with the official iTunes store API, allowing users to search for music, movies, podcasts and more. You can also preview the media the app!",
    features: [
      "Search Music, Movies, more",
      "Live Previews",
      "Search History",
      "Using iTunes API",
    ],
  },
  {
    title: "React Pokedex",
    github: "https://github.com/TJBlackman/React-Pokedex",
    url: "https://react-pokedex-demo.netlify.com/",
    stack: ["HTML 5", "CSS 3", "Javascript", "React"],
    description:
      "A project for learning about React, and for reliving childhood dreams of becoming a Pokemon master. Gotta catch 'em all!",
    features: [
      "Pagination",
      "Live Search",
      "List and Grid View",
      "Using Public API",
    ],
  },
  {
    title: "Tweeter Bot",
    github: "https://github.com/TJBlackman/Tweeter-Bot",
    stack: [
      "HTML 5",
      "CSS 3",
      "Javascript",
      "jQuery",
      "Sass & LESS",
      "NodeJS",
      "MongoDB",
      "PUG",
    ],
    description:
      "Tweeter Bot is a Twitter platform website for queueing tweets ahead of time. Users are able to register an account, then log into the website to programmatically Tweet, Like, and Retweet from their own account based on a super flexible schedule that they set themselves. This is ideal for businesses or any person who needs to automate their Twitter presence.",
    features: [
      "Programmatically Tweet",
      "Schedule Tweets at any time",
      "Tweet directly through app",
      "Review all tweet history",
    ],
  },
  {
    title: "Reddit Alerts",
    github: "https://github.com/TJBlackman/Reddit-Alerts",
    stack: ["HTML 5", "CSS 3", "jQuery", "NodeJS", "PUG", "Express", "MongoDB"],
    description:
      "Reddit Alerts is a single page app (SPA) powered by jQuery, that allows users to set personalized alerts for keywords on subreddits. The app allows users to register an account, enter a comma separated list of search terms, and a valid subreddit. Every minute, the server then checks that subreddit for new posts that include one or more keywords in the title. When a matching post is found, the post is recorded along wih all the keywords that matched. A logged in user can quickly scroll down the list of all the posts in all their subreddits that matched on a keyword, and click a link to go directly to the post or the the post's author's profile.",
    features: [
      "Custom Search Terms",
      "Filter Results",
      "Archive Results",
      "Unlimited SubReddit Monitoring",
    ],
  },
];

const getIcon = (string) => {
  switch (string) {
    case "HTML 5": {
      return htmlIcon;
    }
    case "CSS 3": {
      return cssIcon;
    }
    case "Express": {
      return expressIcon;
    }
    case "jQuery": {
      return jqueryIcon;
    }
    case "Javascript": {
      return jsIcon;
    }
    case "MongoDB": {
      return mongoDbIcon;
    }
    case "NodeJS": {
      return nodeIcon;
    }
    case "React": {
      return reactIcon;
    }
    case "Webpack": {
      return webpackIcon;
    }
    case "Typescript": {
      return tsIcon;
    }
    case "PUG": {
      return pugIcon;
    }
    case "Redux": {
      return reduxIcon;
    }
    case "Sass & LESS": {
      return sassLessIcon;
    }
    case "Postman": {
      return postmanIcon;
    }
    default: {
      console.error('Unknown icon string: "' + string + '"');
    }
  }
};

const useStyle = makeStyles((theme) => ({
  project: {
    padding: theme.spacing(2, 4),
    margin: theme.spacing(3, 0),
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      margin: theme.spacing(5, 0),
    },
  },
  title: {
    ...theme.typography.h5,
  },
  iconStyle: {
    height: "30px",
    width: "auto",
    margin: "0 10px 10px 0",
  },
  descriptionStyle: {
    paddingRight: theme.spacing(3),
  },
}));

export const Projects = ({ limit = false, hideSearch = false }) => {
  const [search, setSearch] = useState("");
  const { project, title, iconStyle, descriptionStyle } = useStyle();
  const projectsToShow = projects
    .filter((p) => {
      if (search === "") {
        return true;
      }
      return search === ""
        ? true
        : JSON.stringify(p)
            .toLowerCase()
            .includes(search.toLowerCase());
    })
    .filter((p, index) => (!!limit ? index < limit : true));
  return (
    <div>
      {hideSearch === false && (
        <Grid container alignItems="center">
          <Grid item style={{ flex: "1 1 auto", marginRight: "20px" }}>
            <TextField
              fullWidth
              label="Search Projects"
              helperText='Search "React", "Express", "Webpack" or really anything...'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              disabled={!search}
              onClick={() => setSearch("")}
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      )}
      {projectsToShow.map((p) => (
        <Box className={project} key={p.title}>
          <Typography className={title} paragraph>
            {p.title}
          </Typography>
          <Grid container>
            <Grid item xs={12} lg={7} xl={8} className={descriptionStyle}>
              <Grid container>
                {p.stack.map((icon) => (
                  <img
                    src={getIcon(icon)}
                    key={icon}
                    className={iconStyle}
                    title={icon}
                    alt={icon + " logo"}
                  />
                ))}
              </Grid>
              <Typography variant="body1" paragraph>
                {p.description}
              </Typography>
              <Typography paragraph>
                {p.url && (
                  <Link
                    href={p.url}
                    target="_blank"
                    style={{ marginRight: "20px" }}
                    variant="body2"
                  >
                    {p.title} Website
                  </Link>
                )}
                {p.github && (
                  <Link
                    href={p.github}
                    target="_blank"
                    style={{ marginRight: "20px" }}
                    variant="body2"
                  >
                    Source Code
                  </Link>
                )}
                {p.postman && (
                  <Link href={p.postman} target="_blank" variant="body2">
                    Postman API Docs
                  </Link>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={5} xl={4}>
              <Typography variant="h6" color="textSecondary">
                Features
              </Typography>
              {p.features.map((item) => (
                <Typography variant="body1" key={item}>
                  {item}
                </Typography>
              ))}
            </Grid>
          </Grid>
        </Box>
      ))}
    </div>
  );
};
